import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "../../i18n";
import { fetchShopOrderReports } from '../../redux/actions';
import { StoreState } from '../../redux/reducers';
import { OrderReport } from "../../redux/reducers/orderReport.reducer";
import { AppDispatch } from '../../redux/store';
import { currentDate, datesArray, initDate } from "../../utils";
import ReportBarChart from "../charts/ReportBarChart";
import ReportLineChart from "../charts/ReportLineChart";
import FormInput from "../utils/FormInput";

const Report: React.FC = () => {
    const t = useT()
    const orderReports = useSelector<StoreState, OrderReport[]>(state => state.orderReports);
    const dispatch = useDispatch<AppDispatch>()
    const [totalCount, setTotalCount] = useState<{ name: string, data: number[] }>({ name: "", data: [] })
    const [totalPrice, setTotalPrice] = useState<{ name: string, data: number[] }>({ name: "", data: [] })
    const [dataSets, setDataSets] = useState<{ name: string, data: { count: number, price: number }[] }[]>([])
    const [fromDate, setFromDate] = useState(initDate(6))
    const [toDate, setToDate] = useState(initDate())
    const [arrayDates, setArrayDates] = useState<string[]>([]);
    const [colors, setColors] = useState<{ count: string[], price: string[] }>({ count: [], price: [] })

    const fetchOrderReports = (fromDate: string, toDate: string) => {
        const endDate = currentDate(toDate, 1)
        dispatch(fetchShopOrderReports(fromDate, endDate))
    }

    React.useEffect(() => {
        let TotalCount: number[] = []
        let TotalPrice: number[] = []

        //category Total Counts
        for (let i in arrayDates) {
            let total = 0
            let price = 0
            for (let orderReport of orderReports) {
                let filter = orderReport.data.filter((d) => d.date === arrayDates[i])[0]
                total = filter ? total + filter.count : total + 0;
                price = filter ? price + filter.price : price + 0;
            }
            TotalCount[i] = total
            TotalPrice[i] = price
        }



        const dataSets = orderReports.map((orderReport: any) => {
            let obj = {
                name: orderReport._id.category[0].names[0].name,
                data: arrayDates.map((arrayDate) => {
                    let filter = orderReport.data.filter((data: any) => data.date === arrayDate)[0]
                    if (filter)
                        return { count: filter.count, price: filter.price }
                    else
                        return { count: 0, price: 0 }
                }
                )
            }
            return obj
        })
        setDataSets(dataSets)
        setTotalCount({ name: "Total Orders", data: TotalCount })
        setTotalPrice({ name: "Total Amount", data: TotalPrice })

    }, [orderReports, fromDate, toDate, arrayDates])
    React.useEffect(() => {
        fetchOrderReports(fromDate, toDate)
    }, [])
    React.useEffect(() => {
        let countColors = [];
        let priceColors = [];
        for (let i = 0; i < orderReports.length; i++) {
            countColors.push('#' + Math.random().toString(10).slice(-6))
            priceColors.push('#' + Math.random().toString(10).slice(-6))
        }
        setColors({ count: countColors, price: priceColors })
    }, [orderReports])
    React.useEffect(() => {
        setArrayDates(datesArray(fromDate, toDate))
        fetchOrderReports(fromDate, toDate)
    }, [fromDate, toDate])

    return (
        <>
            <div className="row m-2">
                <div className="col-lg-4 col-sm-12">
                    <FormInput
                        type='date'
                        label={t('From Date')}
                        placeholder={t('Order value')}
                        containerClass='mb-4'
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />

                </div>
                <div className="col-lg-4 col-sm-12">
                    <FormInput
                        type='date'
                        label={t('To Date')}
                        placeholder={t('Order value')}
                        containerClass='mb-4'
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />

                </div>
            </div>
            <div className="card my-3 shadow-sm">
                <div className="card-header bg-white"><h5 className="header-title mb-3">{t("Order Reports (Count)")}</h5></div>
                <div className="card-body">
                    <div className="row">

                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportBarChart
                                    datasets={dataSets}
                                    colors={colors?.count}
                                    labels={arrayDates}
                                    title={t('count')}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportLineChart
                                    datasets={[totalCount]}
                                    colors={['#AA3089']}
                                    labels={arrayDates}
                                    title=''
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card p-4 m-2" style={{ overflowX: "auto" }}>
                                <table className="table" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr>
                                            <th>{t("Category")}</th>
                                            {arrayDates.map((arrayDate) => { return (<th>{arrayDate}</th>) })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataSets.map((dataSet) => {
                                                const counts = dataSet.data.map((d) => (<td>{d.count}</td>))
                                                return (
                                                    <Fragment>
                                                        <tr>
                                                            <td>{dataSet.name}</td>
                                                            {counts}
                                                        </tr>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        <tr>
                                            <th>{t("Total")}</th>
                                            {totalCount.data.map((total) => (
                                                <th>{total}</th>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card my-3 shadow-sm">
                <div className="card-header bg-white"><h5 className="header-title mb-3">{t("Order Reports (Amount)")}</h5></div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportBarChart
                                    datasets={dataSets}
                                    colors={colors?.price}
                                    labels={arrayDates}
                                    title={t('price')}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="card m-2 p-2">
                                <ReportLineChart
                                    datasets={[totalPrice]}
                                    colors={['#009ef7']}
                                    labels={arrayDates}
                                    title={t('price')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card p-4 m-2" style={{ overflowX: "auto" }}>
                                <table className="table" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr>
                                            <th>{t("Category")}</th>
                                            {arrayDates.map((arrayDate) => { return (<th>{arrayDate}</th>) })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataSets.map((dataSet) => {
                                                const prices = dataSet.data.map((d) => (<td>{Math.round(d.price)}</td>))
                                                return (
                                                    <Fragment>
                                                        <tr>
                                                            <td>{dataSet.name}</td>
                                                            {prices}
                                                        </tr>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        <tr>
                                            <th>{t("Total")}</th>
                                            {totalPrice.data.map((total) => (
                                                <th>{Math.round(total)}</th>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Report