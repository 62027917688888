import { useSelector } from 'react-redux'
import CheckoutCustomerInformation from '../../components/sections/CheckoutCustomerInformation'
import CheckoutDeliveryCharge from '../../components/sections/CheckoutDeliveryCharge'
import CheckoutPaymentSection from '../../components/sections/CheckoutPaymentSection'
import CheckoutShoppingCart from '../../components/sections/CheckoutShoppingCart'
import SectionWithTitle from '../../components/sections/SectionWithTitle'
import ShouldAuthenticate from '../../components/sections/ShouldAuthenticate'
import UserLayout from '../../components/sections/UserLayout'
import ErrorText from '../../components/utils/ErrorText'
import Radio from '../../components/utils/Radio'
import TextArea from '../../components/utils/TextArea'
import CheckoutProvider, { CheckoutContext } from '../../context/CheckoutProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { OrderType } from '../../redux/reducers/order.reducer'
import { Shop } from '../../redux/reducers/shop.reducer'
import style from '../../styles/pages/checkout.module.scss'

const Checkout = () => {
    const cartShop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.cart.shopId) || null)

    const t = useT()
    return <ShouldAuthenticate>
        <UserLayout>
            <CheckoutProvider>
                <CheckoutContext.Consumer>
                    {context => {
                        const orderTypeChangeHandler = (orderType: OrderType) => {
                            if (orderType === 'home') {
                                if (cartShop?.delivery.enabled) {
                                    context?.orderTypeChangeHandler?.('home')
                                }
                            } else if (orderType === 'pick_up') {
                                context?.orderTypeChangeHandler?.('pick_up')
                            }
                        }

                        return <div className='row'>
                            <div className={style.title}>{t("Checkout")}</div>
                            <div className='col-lg-6 order-2 order-lg-1'>
                                <SectionWithTitle title={t("ORDER TYPE")} className='mb-4'>
                                    <Radio
                                        checked={context?.orderType === 'pick_up'}
                                        className='mb-2'
                                        labelClassName={style.radioButtonLabel}
                                        onClick={() => orderTypeChangeHandler('pick_up')}
                                    >{t("Pick-up")}</Radio>

                                    {(cartShop?.plan === 'Premium' || cartShop?.plan?.toString() === '' || cartShop?.plan === undefined) && <div className='hstack gap-2'>
                                        <Radio
                                            checked={context?.orderType === 'home'}
                                            labelClassName={style.radioButtonLabel}
                                            onClick={() => orderTypeChangeHandler('home')}
                                        >{t("Home delivery")}</Radio>
                                        {!cartShop?.delivery.enabled && <ErrorText errorText={t('Not available now')} absolute={false} className={style.notAvailable} />}
                                    </div>}
                                </SectionWithTitle>

                                <CheckoutCustomerInformation />

                                <SectionWithTitle title={t('Additional order information')} className='mb-4'>
                                    <TextArea
                                        placeholder={t('Additional order information')}
                                        value={context?.additionalInfo || ''}
                                        onChange={context?.additionalInfoChangeHandler}
                                        errorText={context?.additionalInfoError}
                                    />
                                </SectionWithTitle>

                                <CheckoutPaymentSection otherPayments={cartShop?.otherPayments} countinueHandler={context?.continueClickHandler} paytrailStatus={cartShop?.paytrail.status} category='Food' />

                            </div>
                            <div className='col-lg-6 order-1 order-lg-2'>
                                <CheckoutShoppingCart />
                                {cartShop?.delivery.enabled && (cartShop?.plan === 'Premium' || cartShop?.plan?.toString() === '' || cartShop?.plan === undefined) && <CheckoutDeliveryCharge />}
                            </div>
                        </div>
                    }}
                </CheckoutContext.Consumer>
            </CheckoutProvider>
        </UserLayout>
    </ShouldAuthenticate>
}

export default Checkout