import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import assets from '../../assets'
import { CheckoutContext, CheckoutContextType } from '../../context/CheckoutProvider'
import { useT } from '../../i18n'
import { StoreState } from '../../redux/reducers'
import { AuthAdmin } from '../../redux/reducers/authAdmin.reducer'
import { CartItem } from '../../redux/reducers/cart.reducer'
import { EventCart } from '../../redux/reducers/eventCart.reducer'
import { FoodType } from '../../redux/reducers/food.reducer'
import { OtherPayments, Shop } from '../../redux/reducers/shop.reducer'
import style from '../../styles/components/checkout-payment-section.module.scss'
import Button from '../utils/Button'
import Radio from '../utils/Radio'
import CheckoutEventRules from './CheckoutEventRules'
import SectionWithTitle from './SectionWithTitle'

interface Props {
    otherPayments: OtherPayments[] | null | undefined
    countinueHandler: React.MouseEventHandler<HTMLButtonElement> | undefined
    eventType?: string
    category: string
    paytrailStatus: string | null | undefined
}
const CheckoutPaymentSection: React.FC<Props> = (props) => {
    const t = useT()
    const eventCart = useSelector<StoreState, EventCart>((state) => state.eventCart);
    const checkoutContext = React.useContext<CheckoutContextType | null>(CheckoutContext)
    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)
    const [width, setWidth] = React.useState<number>(window.innerWidth);

    const cartItems = useSelector<StoreState, CartItem[]>(state => state.cart.items)
    const cartShop = useSelector<StoreState, Shop | null>(state => state.shops.find(s => s.id === state.cart.shopId) || null)

    const showPaymentMethod = useMemo<boolean>(() => {
        if (cartShop?.category === "Product") {
            const productTypes: FoodType[] = []
            cartItems.forEach((ci) => {
                productTypes.push(ci.type!)
            })
            const type: string[] = []
            productTypes.forEach((e) => {
                if (e === "custom") {
                    type.push("true")
                } else {
                    type.push("false")
                }
            })
            return type.includes("false")
        }
        else return true
    }, [cartItems])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 600;

    React.useEffect(() => {
        if (props.eventType === "free")
            checkoutContext?.paymentModeChangeHandler?.('free')
    }, [props.eventType])

    React.useEffect(() => {
        if (!showPaymentMethod)
            checkoutContext?.paymentModeChangeHandler?.('free')
    }, [showPaymentMethod])

    return <SectionWithTitle title={`${(props?.eventType === "free" || checkoutContext?.cultureVoucher !== "no" || !showPaymentMethod) ? '' : t('Choose payment method')}`} className='mb-4'>
        {checkoutContext?.paymentModeError && <span className={style.requiredLabel}>( {checkoutContext?.paymentModeError} )</span>}
        {/* <Radio
            className='mb-3'
            labelClassName={style.label}
            checked={checkoutContext?.paymentMode === 'card'}
            onClick={() => checkoutContext?.paymentModeChangeHandler?.('card')}
        >Add debit/credit/atm card</Radio>
        <div className='mb-4'>
            <div className={style.weAccept}>We accept:</div>
        </div>
        <Radio
            className='mb-2'
            labelClassName={style.label}
            checked={checkoutContext?.paymentMode === 'upi'}
            onClick={() => checkoutContext?.paymentModeChangeHandler?.('upi')}
        >UPI Apps</Radio>
        <div className='row align-items-end mb-4'>
            <div className='col-8'>
                <FormInput
                    label='Please enter UPI ID'
                    type='text'
                    placeholder='Please enter UPI ID'
                />
            </div>
            <div className='col-4'>
                <Button className={style.button}>Verify</Button>
            </div>
        </div> */}
        {showPaymentMethod && props.paytrailStatus === "active" && (authAdmin?.shop?.plan === 'Premium' || authAdmin?.shop?.plan === 'Standard' || authAdmin?.shop?.plan?.toString() === '' || authAdmin?.shop?.plan === undefined) &&
            <div>
                <Radio
                    className={`${checkoutContext?.paymentMode === 'paytrail' ? 'mb-1' : 'mb-4'}`}
                    labelClassName={style.label}
                    checked={checkoutContext?.paymentMode === 'paytrail'}
                    onClick={() => checkoutContext?.paymentModeChangeHandler?.('paytrail')}
                >Paytrail</Radio>
                {isMobile ?
                    checkoutContext?.paymentMode === 'paytrail' && <img src={assets.images.paytrailMobile} alt='paytrail' className={style.paytrailMobile} />
                    :
                    checkoutContext?.paymentMode === 'paytrail' && <img src={assets.images.paytrailWeb} alt='paytrail' className={!props.eventType ? style.paytrailFoodWeb : style.paytrailEventWeb} />
                }
            </div>
        }

        {showPaymentMethod && (props?.otherPayments && (props?.eventType !== "free" && checkoutContext?.cultureVoucher === "no")) &&
            props?.otherPayments.map((otherPayment: OtherPayments) => (
                <Radio
                    className='mb-4'
                    labelClassName={style.label}
                    checked={checkoutContext?.paymentMode === otherPayment.title}
                    onClick={() => checkoutContext?.paymentModeChangeHandler?.(otherPayment.title)}
                >{otherPayment.title} : {otherPayment.detail}</Radio>
            ))
        }
        {eventCart.item?.eventRule && eventCart.item?.eventRule.length > 10 && props.category === "Event" &&
            <CheckoutEventRules />}
        <Button loading={checkoutContext?.loading} onClick={props.countinueHandler} className={style.button}>{t("Continue")}</Button>
    </SectionWithTitle>
}

export default CheckoutPaymentSection