import { nanoid } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "../../i18n";
import { updateShopOtherPayments } from "../../redux/actions/authAdmin.actions";
import { StoreState } from "../../redux/reducers";
import { AuthAdmin } from "../../redux/reducers/authAdmin.reducer";
import { AppDispatch } from "../../redux/store";
import formStyle from '../../styles/components/form.module.scss';
import { toString } from '../../utils';
import { ToastType, useToast } from "../ToastProvider";
import Button from "../utils/Button";
import FormInput from "../utils/FormInput";


interface OtherPayment {
    id: string
    title: string
    detail: string
}
const BankSettings = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const t = useT()

    const authAdmin = useSelector<StoreState, AuthAdmin | null>(state => state.authAdmin)

    const [otherPayments, setOtherPayments] = React.useState<OtherPayment[]>([])
    const [otherPaymentObj, setOtherPaymentObj] = React.useState<OtherPayment>({ id: nanoid(), title: '', detail: '' })
    const [titleError, setTitleError] = React.useState<string>('')
    const [disable, setDisable] = React.useState<boolean>(true)
    const [detailError, setDetailError] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)




    const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target;
        let error = false
        if (toString(value) !== '' && name === "title") {
            if (otherPayments.some((otherPayment: any) => otherPayment.title === toString(value))) {
                setTitleError(t('Title already exists'))
                error = true
            } else {
                setTitleError('')
            }
        }
        else if (toString(value) !== '' && name === "detail") {
            if (otherPayments.some((otherPayment: any) => otherPayment.detail === toString(value))) {
                setDetailError(t('Detail already exists'))
                error = true
            } else {
                setDetailError('')
            }
        }

        if (!error) {
            setOtherPaymentObj({ ...otherPaymentObj, [name]: value });
        }
    }

    const editHandler = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const { name, value } = e.target;
        let error = false
        if (toString(value) !== '' && name === "title") {
            if (otherPayments.some((otherPayment: any) => otherPayment.title === toString(value))) {
                setTitleError(t('Title already exists'))
                error = true
                setDisable(false)
            } else {
                setTitleError('')
                setDisable(false)
            }
        }
        else if (toString(value) !== '' && name === "detail") {
            if (otherPayments.some((otherPayment: any) => otherPayment.detail === toString(value))) {
                setDetailError(t('Detail already exists'))
                error = true
                setDisable(false)
            } else {
                setDetailError('')
                setDisable(false)
            }
        }

        if (!error) {
            setOtherPayments([...otherPayments.map((otherPayment) =>
                otherPayment.id === id ? { ...otherPayment, [name]: value } : otherPayment)]);
        }

    }

    const addClickHandler = () => {
        let error = false;
        if (toString(otherPaymentObj.title) === '') {
            setTitleError(t('Title required'))
            error = true
        }

        if (toString(otherPaymentObj.detail) === '') {
            setDetailError(t('Detail required'))
            error = true
        }
        if (!error) {
            setOtherPayments([...otherPayments, otherPaymentObj])
            setOtherPaymentObj({ id: nanoid(), title: '', detail: '' })
            setDisable(false)
        }

    }
    const deleteHandler = (id: string) => {
        setOtherPayments([...otherPayments.filter((otherPayment) => otherPayment.id !== id)]);
        setDisable(false)
    }

    const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        let error = false

        if (!error) {
            setLoading(true)
            dispatch(updateShopOtherPayments(otherPayments.map((otherPayment) => { return { title: otherPayment.title, detail: otherPayment.detail } }))).then(() => {
                toast(t('Bank Details updated'))
            }).catch(text => {
                toast(text, ToastType.ERROR)
            }).finally(() => {
                setLoading(false)
                setDisable(true);
                setOtherPaymentObj({ id: nanoid(), title: '', detail: '' })
            })
        }
    }

    React.useEffect(() => {
        if (authAdmin?.shop?.otherPayments && authAdmin?.shop?.otherPayments.length !== 0) {
            setOtherPayments(authAdmin?.shop?.otherPayments?.map((otherPayment: any) => {
                return {
                    id: nanoid(),
                    title: otherPayment.title.toString(),
                    detail: otherPayment.detail.toString()
                }
            }) || [])
        }
    }, [authAdmin])
    return (
        <React.Fragment>
            {
                otherPayments.map((otherPayment) => (
                    <React.Fragment>
                        <div className='col-md-6 col-lg-5'>
                            <FormInput
                                type='text'
                                placeholder={t('Title')}
                                name="title"
                                value={otherPayment.title}
                                onChange={(e) => editHandler(e, otherPayment.id)}
                                containerClass='mb-4'
                                disabled
                            />
                        </div>
                        <div className='col-md-6 col-lg-5'>
                            <FormInput
                                type='text'
                                name="detail"
                                placeholder={t('Detail')}
                                value={otherPayment.detail}
                                onChange={(e) => editHandler(e, otherPayment.id)}
                                containerClass='mb-4'
                                disabled
                            />
                        </div>
                        <div className='col-md-6 col-lg-2'>
                            <Button onClick={() => deleteHandler(otherPayment.id)} className={formStyle.saveButton}>{t("Delete")}</Button>
                        </div>
                    </React.Fragment>
                ))
            }
            <div className='col-md-6 col-lg-5'>
                <FormInput
                    type='text'
                    placeholder={t('Title')}
                    name="title"
                    errorText={titleError}
                    value={otherPaymentObj.title}
                    onChange={inputChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-5'>
                <FormInput
                    type='text'
                    placeholder={t('Detail')}
                    name="detail"
                    errorText={detailError}
                    value={otherPaymentObj.detail}
                    onChange={inputChangeHandler}
                    containerClass='mb-4'
                />
            </div>
            <div className='col-md-6 col-lg-2'>
                <Button onClick={addClickHandler} className={formStyle.saveButton} >{t("Add")}</Button>
            </div>
            <div className='mb-4'>
                <Button onClick={saveClickHandler} loading={loading} className={formStyle.saveButton} disabled={disable}>{t("Save")}</Button>
            </div>
        </React.Fragment>
    )
}

export default BankSettings;